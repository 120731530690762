div {
  /*border: 1px red solid;*/
}

html {
  background-image: radial-gradient(circle, rgba(78, 41, 170, 1) 0%, rgba(30, 18, 61, 1) 100%);
  background-size: 100vw 100vw;
  background-color: rgba(30, 18, 61, 1);
  min-height: 100vh;
  color: white;
}

body {
  margin: 0;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

h1 {
  font-weight: 600;
  font-size: 70px;
  color: white;
  margin: 0;
  line-height: 1.35;
  transform: translateX(-5px);
}

h2 {
  font-weight: bold;
  font-size: 47px;
  color: white;
  margin: 0;
}

h3 {
  font-weight: 500;
  font-size: 35px;
  color: white;
  margin: 0;
}

h4 {
  font-weight: 600;
  font-size: 28px;
  color: white;
  margin: 0;
}

h5 {
  font-weight: bold;
  font-size: 20px;
  color: white;
  margin: 0;
  line-height: 1.1;
}

ul {
  list-style-type: square;
}

li {
  margin-bottom: 20px;
  padding-left: 8px;
  text-align: left;
}

p {
  margin: 10px;
}

td {
  vertical-align: top;
}

#root {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

#modal-root {
  position: fixed;
  z-index: 100000;
}

.modal-background {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #00000055;
  backdrop-filter: blur(2px);
}

.mobileMenu {
  display: none;
}

.desktopMenu {
  display: block;
}

.container {
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 14.5rem; // Equal footer height
}

.menu {
  float: right;
}

.hamburger {
  display: none;
}

.menu-mobile {
  display: none;
}

.input {
  border: none;
}

.select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc) !important;
  background-position: calc(100% - 20px) calc(1em + 1px), calc(100% - 15px) calc(1em + 1px), calc(-2.5em) 0.5em !important;
  background-size: 5px 5px, 5px 5px, 1px 1.5em !important;
  background-repeat: no-repeat !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.4; /* Firefox */
}
.input-white::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.2; /* Firefox */
}

.popover {
  background: transparent;
}

.popover-button {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
}

.popover-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  overflow: hidden !important;

  padding: 8px 6px;
  min-width: 160px;
  border-radius: 8px;
  margin: 10px;
  background: white;
  color: black;
  border: none;
  z-index: 10000;
}

.popover-menuitem {
  padding: 0.5rem 1.2rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #000000;
  text-decoration: none;
  text-align: left;
  font-weight: 500;
  width: 96%;
  margin: 0px 4px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 4px;

  &:hover,
  &:focus {
    background-color: #ececec;
    color: #000000;
  }

  &.active {
    background-color: #f3f4f6;
  }
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 8vw !important;
    text-align: center;
    line-height: 1.2;
    margin: 4px 0;
    max-width: 100%;
    transform: none;
  }

  h2 {
    font-size: 18px !important;
    text-align: center;
    line-height: 1.2;
  }

  body {
    font-size: 12pt !important;
    max-width: 100% !important;
  }

  .mobileMenu {
    display: block;
  }

  .desktopMenu {
    display: none;
  }

  .menu {
    display: none;
  }

  .hamburger {
    display: inline-block;
  }

  .menu-mobile {
    display: block;
  }

  .form {
    width: 90% !important;
  }

  .jobcard img {
    left: 70px !important;
  }

  .jobcardtitle {
    width: 60% !important;
  }
}
