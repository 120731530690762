/* Dialog Box */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px;
  padding-inline: 30px;
  padding-bottom: 30px;
  padding-top: 16px;
}

/* Dialog Box Title */
.dialog .title {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Dialog Box Content */
.dialog .content {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Dialog Box Buttons */
.dialog .buttons {
  text-align: right;
}

/* Dialog Box Button Styles */
.dialog .buttons button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}

.dialog .buttons button:hover {
  background-color: #45a049;
}

.imageContainer {
  height: 200px;
  width: 200px;
  border: 1px solid white;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #ddd;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  color: #888;
  font-size: 18px;
}

.card {
  position: absolute;
  bottom: calc(50% - 20px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  font-weight: 700;
}

.fileInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.button {
  cursor: pointer;
  padding: 8px 16px;
}

.label-image {
  display: block;
  height: 100%;
  cursor: pointer;
}

.label-image:hover .image {
  opacity: 0.5;
}

.change-picture {
  display: none;
}
.label-image:hover .change-picture {
  display: block;
}

.preview-image-cropper {
  width: 700px;
  height: 700px;
  margin-left: 40px;
  overflow: auto;
}
